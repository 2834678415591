
@font-face {
  font-family: -apple-system;
  font-display: swap;
  font-weight: 400;
  src: url('https://assets.revolut.com/media/fonts/BasierCircle-Regular.woff2');
}

@font-face {
  font-family: -apple-system;
  font-display: swap;
  font-weight: 500;
  src: url('https://assets.revolut.com/media/fonts/BasierCircle-Medium.woff2');
}

@font-face {
  font-family: -apple-system;
  font-display: swap;
  font-weight: 600;
  src: url('https://assets.revolut.com/media/fonts/BasierCircle-SemiBold.woff2');
}

@font-face {
  font-family: 'Basier Circle';
  font-display: swap;
  font-weight: 400;
  src: url('https://assets.revolut.com/media/fonts/BasierCircle-Regular.woff2');
}

@font-face {
  font-family: 'Basier Circle';
  font-display: swap;
  font-weight: 500;
  src: url('https://assets.revolut.com/media/fonts/BasierCircle-Medium.woff2');
}

@font-face {
  font-family: 'Basier Circle';
  font-display: swap;
  font-weight: 600;
  src: url('https://assets.revolut.com/media/fonts/BasierCircle-SemiBold.woff2');
}

:root {
  font-family: 'Basier Circle', -apple-system;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--rui-color-grouped-background);
}

#root {
  z-index: 0;
  min-width: 0;
  position: relative;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  font-size: inherit;
  padding: 0;
  margin: 0;
  outline: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

button:active {
  color: inherit;
}
